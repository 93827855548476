<template>
  <div class="w-full h-full bg-white flex-column a-center">
    <div class="flex a-center h-full o-hidden" style="max-width: 1300px">
      <div class="flex-column flex-1 flex-s-0 relative" style="margin-bottom: 180px">
        <div class="color-333" style="font-size: 52px;font-weight: 500;line-height: 73px;">
          准备好了吗？
        </div>
        <div class="color-333" style="font-size: 52px;font-weight: 500;line-height: 73px;">
          那就与我们取得联系吧
        </div>
        <div class="border w-full m-v-30" style="height: 1px;"></div>

        <div
          style="font-size: 28px;font-weight: 400;line-height: 44px;color: #333333;width: 521px;z-index: 10001;word-break: break-all;text-align: justify">
          有新项目想和我们谈谈吗？您可以填写右边的表格，让我们了解您的需求，这是一个良好的开始，我们会尽快与您取得联系。当然也欢迎您致电
          <span class="text-blue">15066700883</span>，让我们听到您的声音！
        </div>

        <img alt="" class="absolute" src="../../assets/common/image_background.png"
             style="width: 488px;height: 500px;z-index: 10000;top:300px">
      </div>
      <div class="flex-1 flex-s-0" style="margin-left: 220px;">
        <div class="bold" style="font-size: 30px">留下您的联系方式</div>
        <Input class="m-t-10" placeholder="公司" v-model="entName"/>
        <div class="flex m-v-15">
          <Input class="flex-1 flex-s-0" placeholder="姓名" v-model="name"/>
          <Input class="flex-1 flex-s-0 m-l-20" placeholder="职务" v-model="duty"/>
        </div>
        <div class="flex m-v-15">
          <Input class="flex-1 flex-s-0" placeholder="电话" v-model="phone"/>
          <Input class="flex-1 flex-s-0 m-l-20" placeholder="验证码" v-model="smsCode">
            <span @click="getAboutSmsCodes" slot="append">  {{fTimeout===0?'获取验证码':`已发送（${fTimeout}S）`}}</span>
          </Input>
        </div>
        <div class="bold m-t-25" style="font-size: 30px">您希望我们为您提供什么服务？</div>
        <RadioGroup v-model="servesId">
          <div class="flex m-v-5 flex-wrap">
            <div :key="index" :style={width:getItemWidth(item)} class="m-v-5 flex a-center"
                 v-for="(item,index) in servicesLists">
              <Radio :label="item.functionId">{{item.functionName}}</Radio>
              <Input :readonly="servesId !== item.functionId" class="flex-1" placeholder=""
                     v-if="item.itemType === 2"
                     v-model="otherServices"></Input>
            </div>
          </div>
        </RadioGroup>
        <div class="bold m-t-10" style="font-size: 30px">您的预算？</div>
        <RadioGroup v-model="budgetId">
          <div class="flex m-v-5 flex-wrap">
            <div :key="index" :style={width:getBudgetItemWidth(item)} class="m-v-5 flex a-center"
                 v-for="(item,index) in budgetLists">
              <Radio :label="item.budgetId">{{item.budgetName}}</Radio>
              <Input :readonly="budgetId !== item.budgetId" class="flex-1" placeholder="" v-if="item.itemType === 2"
                     v-model="otherBudget"></Input>
            </div>
          </div>
        </RadioGroup>
        <Button @click="onDataSumitclick" class="m-t-20 w-full" type="primary">提交</Button>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'About',
  data () {
    return {
      entName: '',
      name: '',
      duty: '',
      phone: '',
      budgetId: 0,
      servesId: 0,
      otherServices: '',
      otherBudget: '',
      smsCode: '',
      fTimeout: 0,
      timer: null
    }
  },
  computed: {
    servicesLists () {
      return this.$store.state.app.serviceList || []
    },
    budgetLists () {
      return this.$store.state.app.budgetList || []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getServesList()
      this.getBudgetList()
    },
    onDataSumitclick () {
      if (!this.name) {
        return this.$Message.info('请输入姓名')
      }
      if (!this.phone) {
        return this.$Message.info('请输入手机号码')
      }
      if (!this.smsCode) {
        return this.$Message.info('请输入短信验证码')
      }
      let servesName = ''
      if (this.servesId) {
        let servesList = this.servicesLists.filter(res => {
          return res.functionId === this.servesId
        })
        if (servesList && servesList.length) {
          let item = servesList[0]
          if (item.itemType === 2) {
            servesName = this.otherServices
          } else {
            servesName = item.functionName
          }
        }
      }

      let funcList = [{
        functionId: this.servesId,
        functionName: servesName
      }]
      let budgetName = ''

      if (this.budgetId) {
        let budgetList = this.budgetLists.filter(res => {
          return res.budgetId === this.budgetId
        })
        if (budgetList && budgetList.length) {
          let item = budgetList[0]
          if (item.itemType === 2) {
            budgetName = this.otherBudget
          } else {
            budgetName = item.budgetName
          }
        }
      }

      let params = {
        entName: this.entName,
        name: this.name,
        phone: this.phone,
        duty: this.duty,
        budgetId: this.budgetId,
        budget: budgetName,
        smsCode: this.smsCode,
        funcList: funcList
      }
      this.commitClue(params).then(res => {
        if (res.code === 0) {
          this.restData()
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
            this.fTimeout = 0
          }
        }
        this.$Message.info(res.msg)
      })
    },
    restData () {
      this.entName = ''
      this.name = ''
      this.duty = ''
      this.phone = ''
      this.budgetId = 0
      this.servesId = 0
      this.otherServices = ''
      this.otherBudget = ''
      this.smsCode = ''
    },
    getItemWidth (item) {
      if (item.itemType === 1) {
        return '33%'
      } else {
        return '100%'
      }
    },
    getBudgetItemWidth (item) {
      if (item.itemType === 1) {
        return '25%'
      } else {
        return '100%'
      }
    },
    getAboutSmsCodes () {
      if (this.fTimeout > 0) {
        return
      }
      if (this.phone === '') return this.$Message.info('请输入手机号')
      let reg = /^1[345678]\d{9}$/
      if (!reg.test(this.phone)) return this.$Message.info('请输入正确的手机号')
      let params = {
        phone: this.phone
      }
      this.getAboutSmsCode(params).then(res => {
        if (res.code === 0) {
          let seconds = 60
          this.timer = setInterval(() => {
            --seconds
            this.fTimeout = seconds
            if (seconds <= 0) {
              clearInterval(this.timer)
              this.timer = null
              this.fTimeout = 0
            }
          }, 1000)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    ...mapActions([
      'getServesList',
      'getBudgetList',
      'commitClue',
      'getAboutSmsCode'
    ])
  }
}
</script>

<style lang="scss" scoped>

</style>
